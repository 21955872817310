<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">角色管理</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button @click="addshow=true;addtype=1">添加</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true"
				:data="data" style="width: 100%">
				<el-table-column prop="name" label="角色名称">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间">
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="edit(scope.$index, data)" type="text" size="small">
							编辑
						</el-button>
						<el-button @click.native.prevent="authorize(scope.$index, data)" type="text" size="small">
							授权
						</el-button>
						<el-button @click.native.prevent="del(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 分页器 -->
		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-count="pageCount"
				:page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="30%" :before-close="handleClose" :title="addtype == 1 ? '新增角色' : '编辑角色'">
			<el-form class="form_box" label-position='center' ref="form" :hide-required-asterisk='true' :model="sizeForm"
				 size="mini">
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>角色名称：</p>
							<el-input :maxlength="30" v-model="sizeForm.name" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>角色备注：</p>
							<el-input :maxlength="30" v-model="sizeForm.remarks" clearable></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true' size="large" style="display:flex;align-items:center;justify-content:center;">
					<el-button @click='handleClose'>取消</el-button>
					<el-button v-if="addtype == 1" type="primary" @click="onSubmit">添加</el-button>
					<el-button v-if="addtype == 2" type="primary" @click="onUpate">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<roleIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :choiceId="choiceId" :title="title" />
		<rolePower v-if="dialogVisiblePower" :dialogVisible="dialogVisiblePower" :choiceId="choiceId" :tabs="tabs" />
	</div>
</template>

<script>
	import {
		systemRoleList,
		systemRoleDelete,
		systemRoleMenuTree,
		systemRoleAdd,
		systemRoleUpdate,
	} from "@/api/system/role";
	import roleIndex from '@/components/system/role/index';
	import rolePower from '@/components/system/role/power';
	export default {
		name: "role",
		components: {
			roleIndex,
			rolePower
		},
		data() {
			return {
				addtype: '',
				sizeForm: {
					name:'',
					remarks:'',
					id:''
				},
				addshow: false,
				Buttons: [],
				search: {},
				data: [],
				title: '',
				choiceId: '',
				dialogVisible: false,
				dialogVisiblePower: false,
				ids: [],
				page: 1, // 请求true
				pageSize: 10, // 请求false
				name: '', // 请求false
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				// 角色权限管理
				tabs: []
			};
		},
		mounted() {
			this.Buttons = this.$store.state.index.menuSubButtons

			this.role_list();

			this.$root.eventHub.$on('changeRole', (n) => {
				this.dialogVisible = n.state;
				if (n.type) {
					this.role_list();
				}
			})

			this.$root.eventHub.$on('changeRolePower', (n) => {
				this.dialogVisiblePower = n;
			})
		},
		methods: {

			// 角色列表
			role_list() {
				systemRoleList({
					page: this.page,
					size: this.pageSize,
					name: this.name
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages) {
						this.page = res.data.pages;
						this.role_list();
					}
				})
			},

			// 权限菜单
			roleTreeMenu() {
				systemRoleMenuTree().then(res => {
					if (res.code === 200) {
						this.tabs = res.data;
						this.dialogVisiblePower = true;
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},

			// 分页器
			handleCurrentChange(val) {
				this.page = val;
				this.role_list();
			},

			// 删除角色
			del(cur, tab) {
				this.$confirm('此操作将永久删除【'+ tab[cur].name +'】 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					systemRoleDelete(tab[cur].id).then(res => {
						this.role_list()
						this.$message({
							type: 'success',
							message: res.msg
						});
					})
				}).catch(() => {});
			},
			
			// 编辑按钮
			edit(i, tab) {
				this.addtype = 2
				this.addshow = true
				this.sizeForm = {
					name:tab[i].name,
					remarks:tab[i].remarks,
					id:tab[i].id
				}
			},

			// 弹出层关闭
			handleClose(done) {
				this.addshow = false;
				this.sizeForm = {
					name:'',
					remarks:'',
					id:''
				}
				done();
			},

			// 添加角色
			onSubmit() {
				let that = this;
				if(that.sizeForm.name == ''){
					that.$message.warning('角色名称不能为空')
				}else if(that.sizeForm.remarks == ''){
					that.$message.warning('角色备注不能为空')
				}else{
					systemRoleAdd({
						name: this.sizeForm.name,
						remarks: this.sizeForm.remarks
					}).then(res => {
						if (res.code == 200) {
							this.sizeForm = {
								name:'',
								remarks:'',
								id:''
							}
							this.$message({
								showClose: true,
								message: res.msg,
								type: 'success'
							});
							this.page = 1;
							this.addshow = false
							this.role_list();
						} else {
							this.$message({
								showClose: true,
								message: res.msg,
								type: 'error'
							});
						}
					}).catch(e => {
						this.$message({
							showClose: true,
							message: e.msg,
							type: 'error'
						});
					})
				}
			},

			// 编辑角色
			onUpate() {
				let that = this;
				if(that.sizeForm.name == ''){
					that.$message.warning('角色名称不能为空')
				}else if(that.sizeForm.remarks == ''){
					that.$message.warning('角色备注不能为空')
				}else{
					systemRoleUpdate({
						name: this.sizeForm.name,
						remarks: this.sizeForm.remarks,
						id: this.sizeForm.id
					}).then(res => {
						if (res.code == 200) {
							this.sizeForm = {
								name:'',
								remarks:'',
								id:''
							}
							this.$message({
								showClose: true,
								message: res.msg,
								type: 'success'
							});
							this.page = 1;
							this.addshow = false
							this.role_list();
						} else {
							this.$message({
								showClose: true,
								message: res.msg,
								type: 'error'
							});
						}
					}).catch(e => {
						this.$message({
							showClose: true,
							message: e.msg,
							type: 'error'
						});
					})
				}
			},

			// 授权
			authorize(i, tab) {
				this.choiceId = tab[i].id
				this.dialogVisiblePower = true
				this.roleTreeMenu()
			},
		
		}
	}
</script>

<style scoped>
.table-content {
	margin-top: 20px;
}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
</style>
